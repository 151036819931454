<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center 
        .col-lg-6
          h1 認証コード入力
          p ご入力いただいたメールアドレス宛に「認証コード」を送信しました。メールに記載している「認証コード」を以下に入力してユーザー登録を行ってください。
          p ※登録は24時間以内に行ってください。
          p 「認証コード」のメールが届かない場合は、念のためメールソフトの「迷惑メールフォルダ」をご確認ください。
          p ※その他、ご不明な点は
            router-link(to="/inquiry_form/input") こちら
            |よりお問い合わせください。
          .alert.alert-danger(v-if="message") {{ message }}
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          form.needs-validation(@submit.prevent="exec1" novalidate)
            input.form-control(type="text" placeholder="認証コード" v-model="key" required)
            .invalid-feedback 認証コードを入力してください
            br
            input.btn.btn-primary(type="submit" value="認証コードの確認" v-bind:disabled="isDisabled")
          form.form-group.mt-5(@submit.prevent="exec2")
            .alert.alert-success(v-if="success") {{ success }}
            input.btn.btn-primary(type="submit" value="認証コードを再送する" v-bind:disabled="isDisabled")
</template>

<script>
import {
    Config,
    CognitoIdentityCredentials
} from 'aws-sdk'
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      key: '',
      message: '',
      success: '',
      isDisabled: false,
      errMessage: false,
    }
  },
  mounted() {
    //サインインしていない場合
    this.$user.cognito.getUserAttributes((err, result) => {
      if (err) {
        this.$router.push({ name: 'login', query: { url:this.$route.path }}) //ページ移動
        return
      }
    })
  },
  methods: {
    
    exec1(e) {
      this.isDisabled = true

      this.message = ''

      this.errMessage = false

      this.key = this.key.trim()

      //エラー表示
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation();
        this.isDisabled = false
        return
      }
      
      // アクティベーション処理
      this.$user.cognito.verifyAttribute('email',this.key, {
        onSuccess: (result) => {
          this.isDisabled = false
          this.$router.push('/user/mail/complete') //ページ移動
        },
        onFailure: (err) => {
          if(err.code == 'CodeMismatchException') {
            this.message = '認証コードが正しくありません。'
          }else if(err.code == 'LimitExceededException' || err.code == 'TooManyRequestsException') {
            this.message = 'しばらく時間をおいてから再度お試しください。'
          }else {
            this.errMessage = true
          }
          this.isDisabled = false
          return
        },
      })
      // アクティベーション---ここまで
    },
    exec2() {
      this.isDisabled = true

      this.message = ''

      //認証コード再送
      this.$user.cognito.getAttributeVerificationCode('email'.toLowerCase(), {
        onFailure: (err) => {
          if(err.code == 'LimitExceededException' || err.code == 'TooManyRequestsException') {
            this.message = 'しばらく時間をおいてから再度お試しください。'
          }else {
            this.errMessage = true
          }
          this.isDisabled = false
          return
        },
        onSuccess: (result) => {
        this.success = '認証コードを再送しました。'
        this.isDisabled = false
        }
      })
    },
  }
}
</script>