var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("h1", [_vm._v("認証コード入力")]),
          _c("p", [
            _vm._v(
              "ご入力いただいたメールアドレス宛に「認証コード」を送信しました。メールに記載している「認証コード」を以下に入力してユーザー登録を行ってください。"
            )
          ]),
          _c("p", [_vm._v("※登録は24時間以内に行ってください。")]),
          _c("p", [
            _vm._v(
              "「認証コード」のメールが届かない場合は、念のためメールソフトの「迷惑メールフォルダ」をご確認ください。"
            )
          ]),
          _c(
            "p",
            [
              _vm._v("※その他、ご不明な点は"),
              _c("router-link", { attrs: { to: "/inquiry_form/input" } }, [
                _vm._v("こちら")
              ]),
              _vm._v("よりお問い合わせください。")
            ],
            1
          ),
          _vm.message
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.message))
              ])
            : _vm._e(),
          _vm.errMessage
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("システムエラーが発生しました。"),
                _c(
                  "div",
                  [
                    _vm._v(
                      "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/inquiry_form/input" } },
                      [_vm._v("こちら")]
                    ),
                    _vm._v("よりをお問い合わせください。")
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "needs-validation",
              attrs: { novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.exec1($event)
                }
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.key,
                    expression: "key"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: "認証コード",
                  required: ""
                },
                domProps: { value: _vm.key },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.key = $event.target.value
                  }
                }
              }),
              _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("認証コードを入力してください")
              ]),
              _c("br"),
              _c("input", {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  value: "認証コードの確認",
                  disabled: _vm.isDisabled
                }
              })
            ]
          ),
          _c(
            "form",
            {
              staticClass: "form-group mt-5",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.exec2($event)
                }
              }
            },
            [
              _vm.success
                ? _c("div", { staticClass: "alert alert-success" }, [
                    _vm._v(_vm._s(_vm.success))
                  ])
                : _vm._e(),
              _c("input", {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  value: "認証コードを再送する",
                  disabled: _vm.isDisabled
                }
              })
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }